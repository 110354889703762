<template>
  <div class="app-container">
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="16" class="bg-purple-title">{{ $t('title.BasicInformation') }}</el-col>
      <el-col :span="8" class="float-right">
        <el-button type="primary" @click="createAmazonShipment">{{ $t('page.EmbeddingAmazon') }}</el-button>
        <el-button type="primary" @click="createAmazonShipmentPlan">{{ $t('page.createShipmentPlan') }}</el-button>
      </el-col>
    </el-row>
    <el-form ref="addOutboundForm" :model="amazonShipmentForm" :rules="rules" label-width="100px">
      <div class="el-lt" style="width:100%;">
        <el-row :span="24">
          <el-col :span="8">
            <el-form-item :label="$t('page.outboundOrderNo')" :class="$i18n.locale">{{ DetailsDatas.outboundCode }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('page.outboundType')" :class="$i18n.locale">{{ getOutboundTypeLabel(DetailsDatas.outboundType) }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('page.outboundWH')" :class="$i18n.locale">{{ DetailsDatas.outboundWarehouseName }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('page.outboundSite')" :class="$i18n.locale">{{ DetailsDatas.siteCode }}</el-form-item>
          </el-col>
          <!-- <el-col :span="8">
            <el-form-item :label="$t('page.outboundBoxNum')" :class="$i18n.locale">{{ DetailsDatas.quantity }}</el-form-item>
          </el-col> -->
          <el-col :span="8">
            <el-form-item :label="$t('page.outboundNum')" :class="$i18n.locale">{{ DetailsDatas.expectPairs }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remark">
              <el-input v-model="DetailsDatas.remark" type="textarea" :placeholder="$t('page.inputPlaceholder')" disabled />
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <el-row class="grid-content bg-purple-dark" type="flex">
      <el-col class="bg-purple-title">{{ $t('page.outboundOrderDetail') }}</el-col>
    </el-row>
    <el-table ref="addOutboundTable" class="mb-3" :data="shipmentDetailTable" :header-cell-style="{background:'#fafafa'}">
      <el-table-column
        type="selection"
        width="55"
        align="center"
      />
      <el-table-column
        :label="$t('page.No')"
        type="index"
        width="80"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        prop="sku"
        label="SKU"
        align="center"
      />

      <el-table-column
        prop="upc"
        label="UPC"
        align="center"
      />
      <el-table-column
        prop="destinationFnsku"
        label="FNSKU"
        align="center"
      />
      <el-table-column
        prop="style,color,size"
        :label="$t('page.Specifications')"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.style }}/{{ scope.row.color }}/{{ scope.row.size }}
        </template>
      </el-table-column>
      <el-table-column
        prop="expectPairs"
        label="预期出库数量"
        align="center"
      />
      <el-table-column
        prop="createByName"
        label="创建人"
        align="center"
      />
      <el-table-column
        prop="createTime"
        label="操作时间"
        align="center"
      />
    </el-table>
    <el-row class="grid-content bg-blue" type="flex" justify="center">
      <el-button @click="cancle()">{{ $t('title.back') }}</el-button>
    </el-row>
  </div>
</template>
<script>
import { Mixin } from '@/mixin/mixin.js'
import { getFindOutboundDetail,
  queryOutboundDetailTotal, findOutbound } from '@/api/stock-out'
export default {
  mixins: [Mixin],
  data() {
    return {
      DetailsDatas: {},
      amazonShipmentForm: {
        outboundCode: '',
        sendSiteCode: '',
        warehouseName: '',
        outboundType: '',
        quantity: '',
        pairs: '',
        remark: '',
        platformCode: ''
      },
      shipmentDetailTable: [],
      rules: {}
    }
  },
  computed: {
  },
  mounted() {
    const { row } = this.$route.query
    this.DetailsDatas = row && JSON.parse(row) || {}
    console.log('%c 🥗  this.DetailsDatas: ', 'font-size:20px;background-color: #4b4b4b;color:#fff;', this.DetailsDatas)
    if (!Object.keys(this.DetailsDatas).length) {
      return
    }

    this._findCollectDetail(this.DetailsDatas)
  },
  methods: {
    // 获取详情数据
    async _findCollectDetail(data) {
      const { outboundCode, tableSuffix } = data
      const params = { outboundCode, tableSuffix }
      const { datas } = await getFindOutboundDetail(params)

      this.shipmentDetailTable = datas
    },
    createAmazonShipment() {
      // const { outboundCode, tableSuffix } = this.DetailsDatas
      this.$router.push({
        path: 'create-shipment',
        query: this.DetailsDatas
      })
    },
    createAmazonShipmentPlan() {
      const { outboundCode, tableSuffix, siteCode } = this.DetailsDatas
      const { platformCode, shopCode } = this.amazonShipmentForm
      const { row } = this.$route.query
      this.$router.push({
        path: 'generat-shipment',
        query: { outboundCode, platformCode, siteCode, shopCode, tableSuffix, row }
      })
    },
    getQueryData() {
      const { outboundCode } = this.$route.query
      this.amazonShipmentForm.outboundCode = outboundCode
      this._findOutbound(outboundCode)
      this._queryOutboundDetail(outboundCode)
    },
    cancle() {
      this.$router.go(-1)
    },
    async _findOutbound(outboundCode) {
      const { datas } = await findOutbound(outboundCode)
      this.amazonShipmentForm = datas
    },
    async _queryOutboundDetail(outboundCode) {
      const { datas } = await queryOutboundDetailTotal(outboundCode)
      this.outboundDetailTable = datas.list
    }
  }
}
</script>
<style lang="scss" scope>
.float-right {
  display: flex;
  justify-content: flex-end;
}
</style>
